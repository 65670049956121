<template>
  <signup-template class="form" :progress-amount="40" data-test="layout">
    <template #text>
      <h1 class="search-filter__title" data-test="title">
        {{ $t(`${langPath}.title`, { name: currentUser.name }) }}
      </h1>
      <h2 class="search-filter__description">
        {{ $t(`${langPath}.lastStepDescription`) }}
      </h2>
    </template>

    <template #form>
      <section class="search-filter__form">
        <label class="search-filter__label">{{ $t(`${langPath}.labels.career`) }}</label>
        <app-searchable-select
          name="career"
          data-test="career-select"
          :items="careerOptions"
          v-model="careerId"
          v-validate="'required'"
          :data-vv-as="'careerId'"
          data-vv-validate-on="input"
          :search-placeholder="$t(`${langPath}.placeholders.careerSearch`)"
          :select-placeholder="$t(`${langPath}.placeholders.careerSelect`)"
        />

        <app-tags-selector
          class="search-filter__skills"
          name="skills"
          v-model="skills"
          v-validate="false"
          :options="suggestedSkills"
          :autocomplete-placeholder="$t(`${langPath}.placeholders.skills`)"
          data-test="skills-select"
          data-vv-validate-on="blur"
          :search-function="getSkillsFunctions"
          :selected-title="$t(`${langPath}.labels.suggestedSkills`)"
          item-name=""
          open-scope
        />

        <label class="search-filter__label">
          {{ $t(`${langPath}.labels.englishRequired`) }}
        </label>

        <div class="search-filter__radio input-wrapper">
          <ev-radio
            id="english-required-true"
            :label="$t(`${langPath}.labels.englishRequiredTrue`)"
            :value="englishRequiredInputValue"
            checked="true"
            data-test="radio-option-true"
            name="englishRequiredTrue"
            color="blue"
            class="search-filter__radio-button"
            @input="setEnglishRequired(true)"
          />
          <ev-radio
            id="english-required-false"
            :label="$t(`${langPath}.labels.englishRequiredFalse`)"
            :value="englishRequiredInputValue"
            checked="false"
            data-test="radio-option-false"
            name="englishRequiredFalse"
            color="blue"
            class="search-filter__radio-button"
            @input="setEnglishRequired(false)"
          />
        </div>

        <div class="search-filter__submit">
          <ev-button full-width data-test="submit-button" @click="submitSearch">
            {{ $t(`${langPath}.searchCandidates`) }}
          </ev-button>
        </div>
      </section>
    </template>
  </signup-template>
</template>

<script>
import SignupTemplate from './components/SignupTemplate'
import EvButton from '@revelotech/everestV2/EvButton'
import EvRadio from '@revelotech/everestV2/EvRadio'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, SIGNUP, NOTIFICATION_CARDS } from '@/store/namespaces'

import autocomplete from '@/repository/companies-app/autocomplete'
import careers from '@/repository/companies-app/careers'
import suggestions from '@/repository/companies-app/suggestions'
import events from '@/repository/companies-app/events'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const signUpHelper = createNamespacedHelpers(SIGNUP)
const notificationsHelper = createNamespacedHelpers(NOTIFICATION_CARDS)

const DEVELOPER_GROUP_ID = '8'

export default {
  name: 'SignupSearchFilter',
  components: {
    EvButton,
    EvRadio,
    SignupTemplate
  },
  data () {
    return {
      langPath: __langpath,
      skills: [],
      careerId: '',
      careerOptions: [],
      englishRequired: undefined,
      suggestedSkills: []
    }
  },
  computed: {
    ...currentUserHelper.mapState(['currentUser']),
    submittable () {
      const hasSkills = this.skills.length > 0
      const hasCareer = this.careerId !== ''

      return hasSkills && hasCareer
    },
    englishRequiredInputValue () {
      return this.englishRequired?.toString() || ''
    }
  },
  watch: {
    async careerId (newValue) {
      const skills = await suggestions.getSkills(newValue)

      this.suggestedSkills = skills.map((skill) => ({
        value: skill.id,
        label: skill.name
      }))
    },
    skills (newValue) {
      this.skills = (newValue.length > 3) ? newValue.slice(0, 3) : newValue
    }
  },
  async mounted () {
    events.createEvent({ event: 'search_filter', data: {} })
    this.careerOptions = await careers.getFiltersCareers()
    this.careerOptions = this.careerOptions
      .map((careerFilterGroup) => ({
        title: careerFilterGroup.name,
        id: careerFilterGroup.id,
        items: careerFilterGroup.careers.map((career) => ({
          label: career.name,
          value: career.id
        }))
      }))
      .sort((group) => group.id === DEVELOPER_GROUP_ID ? -1 : 1)
  },
  methods: {
    ...signUpHelper.mapActions(['setSearchParams']),
    ...notificationsHelper.mapActions(['createNotificationCard']),
    async getSkillsFunctions (name) {
      const skills = await autocomplete.getSkills(name)

      return skills.map((skill) => ({
        value: skill.id,
        label: skill.text
      }))
    },
    submitSearch () {
      if (!this.submittable) {
        this.createNotificationCard({
          message: this.$t(`${this.langPath}.errors.message`),
          modifier: 'error'
        })
        return
      }

      this.setSearchParams({
        careerId: this.careerId,
        englishRequired: this.englishRequired,
        skills: this.skills.map(skill => skill.value)
      })

      this.$router.push({ name: 'SignupSearchSteps' })
    },
    setEnglishRequired (value) {
      this.englishRequired = value
    }
  }
}
</script>

<style lang="scss" scoped>
.search-filter {
  &__title {
    @extend %h4;

    @include margin(bottom, 4);

    color: var(--tx-neutral-blank);
  }

  &__description {
    @extend %h6;

    color: var(--tx-neutral-blank);
  }

  &__form {
    background-color: var(--bg-neutral-blank);
    height: 100vh;

    @include breakpoint(md) {
      padding: $base*4;
    }
  }

  &__location-remote {
    margin-bottom: $base*6;
    margin-top: $base*2;
  }

  &__skills {
    margin-top: $base*6;
  }

  &__label {
    margin-bottom: $base*2;
    margin-top: $base*2;
  }

  &__radio {
    display: flex;
    font-size: 14px;
    line-height: $base*5;
    padding-top: $base*1;

    &-button {
      align-items: center;
      display: inline-flex;
      margin-bottom: $base*3;
      margin-right: $base*6;
    }
  }

  &__submit {
    padding-top: $base*6;
  }

  @include breakpoint(md) {
    &__form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 $base*30;
    }

    &__label {
      font-size: 0.875rem;
      margin-bottom: $base*2;
      margin-top: $base*4;
    }

    &__title {
      font-size: $base*8;
      line-height: $base*10;
    }

    &__description {
      font-size: $base*6;
      line-height: $base*8;
    }
  }
}
</style>
